import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { Progress as QuestProgress } from 'cccisd-laravel-assignment';
import query from './query.graphql';

// shouldn't hardcode ID's, but meh *shrugs*
const AUS_ORGANIZATION_ID = 130;
const AUS_QUEST_ID = 10;
const AUS_DEPLOYMENT_ID = 38;

const CAN_ORGANIZATION_ID = 65;
const CAN_QUEST_ID = 8;
const CAN_DEPLOYMENT_ID = 27;

const OLD_CAN_ORGANIZATION_ID = 2;
const OLD_CAN_QUEST_ID = 1;
const OLD_CAN_DEPLOYMENT_ID = 18;

const Progress = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query,
                variables: {},
                fetchPolicy: 'network-only',
            });

            const respData = resp.data;
            setData(respData);
            setIsLoading(false);
        }

        getData();
    }, []);

    if (isLoading || !data) {
        return <Loader loading />;
    }

    let assignmentId = -1;
    let deploymentId = -1;
    switch (data?.groups?.organization?.group?.groupId) {
        case AUS_ORGANIZATION_ID:
            assignmentId = AUS_QUEST_ID;
            deploymentId = AUS_DEPLOYMENT_ID;
            break;
        case CAN_ORGANIZATION_ID:
            assignmentId = CAN_QUEST_ID;
            deploymentId = CAN_DEPLOYMENT_ID;
            break;
        case OLD_CAN_ORGANIZATION_ID:
            assignmentId = OLD_CAN_QUEST_ID;
            deploymentId = OLD_CAN_DEPLOYMENT_ID;
            break;
        default:
            assignmentId = CAN_QUEST_ID;
            deploymentId = CAN_DEPLOYMENT_ID;
    }

    return (
        <QuestProgress
            {...{
                ...props,
                assignmentId,
                assignmentIdWhitelist: [assignmentId],
                deploymentId,
                deploymentIdWhitelist: [deploymentId],
            }}
        />
    );
};

export default Progress;
